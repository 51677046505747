@import "../../styles/responsive.scss";

.internshipContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: auto;

    .summaryButtonGroups {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        @include mobile {
            flex-direction: column;
        }

        .sendMessageButton {
            @include mobile {
                margin-top: 10px;
            }
        }

        .ant-btn {
            @include mobile {
                width: 100%;
            }

            &:not(:first-child) {

                margin-left: 10px;
                @include mobile {
                    margin-left: 0;
                }
                
            }
        }
    }

    

    .summary {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        position: relative;
        width: 100%;

        .summaryGroups {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            @include mobile {
                flex-direction: column;
            }

            &>div:not(:first-child) {
                @include mobile {
                    margin-top: 15px;
                }
            }
        }

        

        @include mobile {
            width: 100%;
        }

        h5.ant-typography {
            color: var(--primary-color);
        }

        .ant-card {
            width: 91%;
            box-shadow: 0 5px 25px 0 var(--box-shadow-color);
            -webkit-box-shadow: 0 5px 25px 0 var(--box-shadow-color);

            @include mobile {
                width: 100%;
            }

            .ant-card-head {
                font-weight: bold;
                border-bottom: none !important;

                .ant-card-head-title {
                    text-align: center;
                }
            }
        }

        h5.ant-typography svg {
            margin-right: 10px !important;
        }
    }

    .companyName {
        color: var(--black) !important;

        .by {
            color: var(--gray-6);
            font-size: 16px;
        }
    }

    .internship {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: auto;
        width: 90%;
    
        @include mobile {
            flex-direction: column;
            width: 100%;
        }

        .internshipContent {
            padding: 20px;
            position: relative;
            flex: 2;

            .bulletPoint {
                margin-right: 5px;
                transform: scale(0.6);
            }

            h2.ant-typography {
                color: var(--primary-color);

                @include mobile {
                    font-size: 22px;
                }
            }

            @include mobile {
                width: 96%;
                margin-left: 2% !important;
            }

            h4.ant-typography {
                margin-top: 0;
            }

            .ant-row {
                margin-bottom: 20px;
            }

            .summaryContent {
                position: relative;
                width: 100%;
                border-radius: 6px;
                padding: 10px;
                white-space: pre-wrap;
            }

            .li {
                margin-left: 15px;
            }
        }

        .review.boxContainer {
            box-shadow: none !important;
            flex: 1;
        }
    }
}
