.verifyEmailPageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
    background-color: #1f62fa;
    padding-block: 50px;

    .ant-btn {
        margin-bottom: 10px;
    }

    .ant-typography {
        color: var(--white);
        font-weight: bolder !important;
        margin-bottom: 0 !important;
    }
}