@import "../../styles/responsive.scss";

.profileSidebarContainer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    @include mobile {
        flex-direction: column;
    }

    .profileSidebar {
        position: relative;
        width: 20%;
        min-height: 70vh;
        height: auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 20px;

        @include mobile {
            width: 100%;
        }

        .imageContainer {
            position: relative;
            width: 250px;
            height: 250px;
            border-radius: 10px;
            overflow: hidden;

            .displayName {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 50px;
                background-color: rgba(38, 174, 97, 0.8);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: var(--white);
                font-weight: bold;
            }
        }

        .buttons {
            margin-top: 15px;
            position: relative;
            width: 250px;

            .ant-btn {
                margin-bottom: 5px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                height: 50px;

                svg {
                    margin-right: 10px;
                }
            }
        }
    }

    .profileSidebarContent {
        position: relative;
        width: 80%;
        min-height: 70vh;
        height: auto;

        @include mobile {
            width: 100%;
        }
    }
}