@import "./styles/colors";
@import "./styles/variables";
@import "./styles/responsive";
@import "./styles/common";

@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

body {
  margin: 0;
  font-family: Montserrat, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  position: relative;
  width: 100%;
  height: 100vh;
  font-weight: 600;
}

.ant-col-offset-2 {
  @include mobile {
    margin-left: 0 !important;
  }
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-track {
  background: var(--gray-5);
}

::-webkit-scrollbar-thumb {
  background: var(--gray-6);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--gray-7);
}

.disabledApp {
  pointer-events: none;
}

.ant-avatar-string {
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.ant-avatar {
  background-color: var(--primary-color) !important;
}

.ant-btn-primary {
  @include CommonGradientBackground()
}

.ant-btn-primary[disabled] {
  background-image: none !important;
  background-color: var(--gray-6) !important;
  color: var(--white) !important;
}

.formRequiredStar {
  transform: scale(0.5);
}

.ant-upload-picture-card-wrapper {
  max-width: 120px !important;
}

.boxContainer {
  width: 90%;
  padding: 20px;
  margin-block: 20px;
  border-radius: 6px;
  box-shadow: 0 5px 25px 0 var(--box-shadow-color);
  -webkit-box-shadow: 0 5px 25px 0 var(--box-shadow-color);

  @include mobile {
    width: 96%;
    padding: 10px;
    margin-block: 10px;
  }
}
