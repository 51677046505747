@import "../../styles/responsive.scss";
@import "../../styles/common.scss";

.mainPageContainer {
    position: relative;
    width: 100%;

    .topSection {
        position: relative;
        width: 100%;
        height: 100vh;
        background-size: 100% 100vh;
        background-repeat: no-repeat !important;

        @include desktop {
            background-attachment: fixed;
        }

        @include mobile {
            background-size: cover;
            background-position-x: 70%;
        }

        .blur {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0.8;
            background: linear-gradient(to right,rgba(50,50,50,1) 20%, rgba(50,50,50,.95) 50% ,rgba(50,50,50,0.1) 80%,rgba(50,50,50,0) 100%);

            @include mobile {
                justify-content: flex-start;
                padding-top: 15px;
            }

            .searchSection {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                margin-left: 10%;
                width: 70%;
                height: 50vh;

                @include tablet {
                    width: 86%;
                    margin-left: 7%;
                }

                @include mobile {
                    justify-content: flex-start;
                    height: 100vh;
                    width: 90%;
                    margin-left: 5%;
                    margin-top: 50px;
                }

                .searchEngine {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: 80%;
                    height: 60px;
                    border-radius: 40px;
                    margin-top: 30px;
                    box-shadow: 0 0 10px rgba($color: #ffffff, $alpha: 0.5);

                    @include mobile {
                        flex-direction: column;
                        width: 100%;
                        margin-top: 50px;
                        height: auto;
                        border-radius: 10px;
                    }

                    .ant-input {
                        height: 100%;
                        width: 50%;
                        border: none;
                        outline: none;
                        padding-inline: 30px;
                        border-radius: 40px 0 0 40px;                        

                        &::placeholder {
                            color: gray;
                        }

                        @include mobile {
                            width: 100%;
                            height: 50px;
                            border-radius: 10px 10px 0 0;
                        }
                    }

                    .ant-select, .ant-select-selector {
                        height: 100%;
                    }

                    .ant-select-selection-item {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        @include mobile {
                            padding-left: 18px;
                        }
                    }

                    .ant-select {
                        width: 40%;
                        border: none;
                        outline: none;

                        @include mobile {
                            width: 100%;
                            height: 50px;
                        }
                    }

                    .ant-btn {
                        width: 10%;
                        height: 100%;
                        border: none;
                        outline: none;
                        border-radius: 0 40px 40px 0;

                        @include CommonGradientBackground();

                        @include mobile {
                            width: 100%;
                            height: 50px;
                            border-radius: 0 0 10px 10px;
                        }
                    }
                }
    
                .ant-typography {
                    color: var(--white);
                    margin-block: 0;

                    &:not(:nth-child(3)) {
                        font-size: 54px;
                        line-height: 1.2;
                        font-weight: 900;

                        @include tablet {
                            font-size: 42px;
                        }

                        @include mobile {
                            font-size: 30px;
                        }
                    }

                    &:nth-child(3) {
                        margin-top: 20px;
                    }

                    &>span {
                        background-color: rgb(31 98 250 / 20%);
                        padding-inline: 5px;
                    }
                }
            }
        }
    }

    .internshipSection {
        position: relative;
        width: 100%;
        padding: 15px;

        .internshipCardContainer {
            width: 20%;
            float: left;
            margin-left: 4%;
            margin-bottom: 20px;

            @include mobile {
                width: 100%;
                margin-left: 0;
            }
        }

        .ant-skeleton {
            width: 30%;
            float: left;
            margin-left: 3%;
            margin-bottom: 20px;

            @include mobile {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    .bottomSection {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: auto;
        padding-block: 15px;
    }
}