@import "../../styles/responsive.scss";

.info-modal {
    width: 50% !important;

    @include mobile {
        width: 90% !important;
    }

    .applicantProfileContainer {
        padding: 0 !important;
    }
}