@import "../../styles/responsive.scss";

.loginPageContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: calc(100vh - var(--header-height) - var(--footer-height));

    .loginForm {
        position: relative;
        width: 40%;
        padding: 30px 20px;
        border-radius: 6px;
        box-shadow: 0 5px 25px 0 var(--box-shadow-color);
        -webkit-box-shadow: 0 5px 25px 0 var(--box-shadow-color);
        margin-block: 20px;

        .forgotPassword {
            float: right;
        }

        @include notDesktop {
            width: 90%;
        }

        .errorMessage {
            color: var(--red-7);
        }
    }
}