@import "../../styles/responsive.scss";

.resetExistPasswordPageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: calc(100vh - var(--header-height) - var(--footer-height));

    .errorMessage {
        color: var(--red-7);
    }

    .resetExistForm {
        position: relative;

        .ant-input-group.ant-input-group-compact .ant-form-item {
            width: 49%;
            
            &:first-child {
                margin-right: 2%;

                @include notDesktop {
                    margin-right: 0;
                }
            }

            @include notDesktop {
                width: 100%;
            }
        }
    }
}