@import "../../../styles/responsive.scss";

.earningsPageContainer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .earningsContent {
        display: flex;
        flex-direction: column;
        position: relative;
        height: auto;

        .matchedEarning {
            background-color: var(--box-shadow-color);
        }

        .ant-avatar {
            left: 50%;
            transform: translateX(-50%);
            width: 50px;
            height: 50px;

            .ant-avatar-string {
                font-size: 15px;
            }
        }
    }
}