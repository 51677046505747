.actionBox {
    position: relative;

    &>.ant-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;
        z-index: 2;
    }
}