@import "../../../styles/responsive.scss";

.createUpdateBillingAccountContainer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .errorMessage {
        color: var(--red-7);
    }

    .formContent {
        position: relative;
        height: auto;

        .ant-input-group.ant-input-group-compact .ant-form-item {
            width: 49%;

            @include desktop {
                margin-bottom: 0;
            }
            
            &:first-child {
                margin-right: 2%;

                @include notDesktop {
                    margin-right: 0;
                }
            }

            @include notDesktop {
                width: 100%;
            }
        }
    }
}