:root {
    --white: #fff;
    --black: #000;
    --bg-color: #f4f4f4;
    --transparent: transparent;

    --primary-color: #297FCA;
    --box-shadow-color: rgb(41 128 185 / 15%);
  
    --gray-1: #FAFAFA;
    --gray-2: #F5F5F5;
    --gray-3: #EEEEEE;
    --gray-4: #E0E0E0;
    --gray-5: #BDBDBD;
    --gray-6: #9E9E9E;
    --gray-7: #757575;
  
    --purple-1: #daade2;
    --lavender: rgb(230,230,250);
  
    --red-3: #E57373;
    --pink-3: #F06292;
    --purple-3: #BA68C8;
    --deep-purple-3: #9575CD;
    --indigo-3: #7986CB;
    --blue-3: #64B5F6;
    --light-blue-3: #4FC3F7;
    --sky-blue-3: #87CEFA;
    --cyan-3: #4DD0E1;
    --teal-3: #4DB6AC;
    --green-3: #81C784;
    --light-green-3: #AED581;
    --lime-3: #DCE775;
    --yellow-3: #FFF176;
    --smber-3: #FFD54F;
    --orange-3: #FFB74D;
    --deep-orange-3: #FF8A65;
    --wheat-3: #F5DEB3;
    --brown-3: #A1887F;
    --blue-grey-3: #90A4AE;
  
    --red-5: #F44336;
    --pink-5: #E91E63;
    --purple-5: #9C27B0;
    --deep-purple-5: #673AB7;
    --indigo-5: #5C6BC0;
    --blue-5: #2196F3;
    --light-blue-5: #03A9F4;
    --cyan-5: #00BCD4;
    --teal-5: #009688;
    --green-5: #4CAF50;
    --light-green-5: #8BC34A;
    --lime-5: #CDDC39;
    --yellow-5: #FFEB3B;
    --smber-5: #FFC107;
    --orange-5: #FF9800;
    --deep-orange-5: #FF5722;
    --brown-5: #795548;
    --blue-grey-5: #607D8B;
  
    --red-7: #D32F2F;
    --pink-7: #AD1457;
    --purple-7: #7B1FA2;
    --deep-purple-7: #4527A0;
    --indigo-7: #303F9F;
    --blue-7: #1976D2;
    --light-blue-7: #0288D1;
    --cyan-7: #0097A7;
    --teal-7: #00796B;
    --green-7: #388E3C;
    --light-green-7: #689F38;
    --lime-7: #AFB42B;
    --yellow-7: #FBC02D;
    --amber-7: #FFA000;
    --orange-7: #F57C00;
    --deep-orange-7: #E64A19;
    --brown-7: #5D4037;
    --blue-grey-7: #455A64;
} 