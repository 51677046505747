@import "../../styles/responsive.scss";

.signupPageContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: calc(100vh - var(--header-height) - var(--footer-height));

    .signupForm {
        position: relative;
        width: 50%;
        padding: 20px 20px;
        border-radius: 6px;
        box-shadow: 0 5px 25px 0 var(--box-shadow-color);
        -webkit-box-shadow: 0 5px 25px 0 var(--box-shadow-color);
        margin-block: 20px;

        .ant-form-item {
            margin-top: 0;
            margin-bottom: 10px;
        }

        @include notDesktop {
            width: 90%;
        }

        .errorMessage {
            color: var(--red-7);
        }

        .successNotification {
            color: var(--primary-color);
            font-weight: bold;
        }

        .errorNotification {
            color: var(--red-7);
            font-weight: bold;
        }
    }
}