@import "../../styles/responsive.scss";

@mixin FilterSection {
    .resetButton {
        margin-top: 10px;
    }

    .durationFilter {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        &>div {
            flex: 1;

            &:first-child {
                margin-right: 5px;
            }
        }
    }

    .ant-card {
        position: relative;
        width: 80%;
        margin-left: 10%;
        box-shadow: 0 5px 25px 0 var(--box-shadow-color);
        -webkit-box-shadow: 0 5px 25px 0 var(--box-shadow-color);

        @include mobile {
            width: 100%;
            margin-left: 0;
        }

        .ant-card-head-title {
            text-align: center;
            font-weight: bold;
            color: var(--primary-color);
        }

        .ant-select {
            width: 100%;
            margin-bottom: 20px;
        }

        .ant-input {
            width: 100%;
            margin-bottom: 20px;
        }

        .ant-btn {
            width: 100%;
        }
    }
}

.searchInternshipContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    width: 100%;
    height: auto;
    padding: 30px;

    @include mobile {
        flex-direction: column;
        padding: 10px;
    }

    .mobileFilterSection {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        width: 100%;

        .ant-card {
            margin-top: 10px;
        }

        @include notMobile {
            display: none;
        }

        @include FilterSection();
    }

    .filterSection {
        position: relative;
        width: 40%;

        @include mobile {
            display: none;
        }

        @include FilterSection();
    }

    .resultSection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        width: 60%;

        .resultTopSection {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 100%;

            @include mobile {
                margin-top: 20px;
            }

            .ant-pagination {
                align-self: flex-end;

                @include mobile {
                    align-self: center;
                }

                @include desktop {
                    margin-left: 50px;
                }
            }
        }

        .spaceBetween {
            justify-content: space-between !important;
        }

        .internshipListItemContainer {
            margin-bottom: 15px;
        }

        .ant-empty {
            align-self: center;
        }

        @include mobile {
            width: 100%;
        }
    }
}