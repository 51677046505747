@import "../../styles/responsive.scss";

.listCreatorInput {
    display: flex;
    flex-direction: row;
    width: 100%;

    .ant-btn-icon-only {
        vertical-align: none;
    }

    .ant-input {
        margin-right: 3px;
    }
} 