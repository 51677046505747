@import "../../../styles/responsive.scss";

.billingAccountsContainer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2.ant-typography {
        @include mobile {
            font-size: 22px;
        }
    }

    .ant-btn {
        margin-bottom: 20px;
    }

    .ant-typography {
        color: var(--primary-color);
    }

    .billingContent {
        position: relative;
        height: auto;
    }
}