@import "../../styles/responsive.scss";

.internshipListItemContainer {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 5px 25px 0 var(--box-shadow-color);
    -webkit-box-shadow: 0 5px 25px 0 var(--box-shadow-color);

    .listItemLeftSide {
        padding: 20px;

        .titleWithLocation {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
        }

        .locationText {
            font-size: 12px;
        }
    }

    .ant-col-4 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .label {
        @include mobile {
            display: none;
        }
    }

    .partTimeLabel {
        border: 0.5px solid var(--gray-3);
        border-radius: 5px;
        padding: 2px;
        width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include mobile {
            width: 50px;
        }
    }

    .ant-avatar {
        width: 100px;
        height: 100px;

        @include mobile {
            width: 50px;
            height: 50px;
        }

        .ant-avatar-string {
            font-size: 50px;

            @include mobile {
                font-size: 20px;
            }
        }
    }

    .ant-typography {
        color: var(--primary-color);
    }

    .companyName {
        color: var(--black) !important;

        .by {
            color: var(--gray-6) !important;
            font-size: 16px;
        }
    }

    span.ant-typography {
        font-weight: 800;
        color: var(--black) !important;
    }

    h4.ant-typography {
        margin-top: 0 !important;

        @include mobile {
            font-size: 18px;
        }
    }

    h3.ant-typography {
        @include mobile {
            font-size: 20px;
        }
    }

    h5.ant-typography {
        @include mobile {
            font-size: 12px;
        }
    }

    h5.ant-typography svg {
        margin-right: 10px !important;
    }

    .ant-row .ant-row {
        margin-block: 15px;
    }

    .avatarContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex: 1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0;

        @include mobile {
            background-image: none !important;
        }

        @include tablet {
            background-image: none !important;
        }

        .blurredContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex: 1;
            cursor: pointer;

            @include mobile {
                background-color: transparent !important;
            }

            @include tablet {
                background-color: transparent !important;
            }
        }

        &>.heart {
            width: 20px;
            height: 20px;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}