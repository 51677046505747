:root {
    --border-radius-xs: 1px;
    --border-radius-sm: 3px;
    --border-radius-md: 5px;
    --border-radius-lg: 7px;
    --border-radius-xl: 9px;

    --scrollbar-width: 15px;

    --header-height: 70px;
    --footer-height: 400px;
}