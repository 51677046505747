@import "../../styles/responsive.scss";

.messagesContainer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .matchedMessage {
        background-color: var(--box-shadow-color);
    }

    @include mobile {
        margin-block: 10px;
    }

    .disableAction {
        cursor: default;
    }

    .buttonContainer {
        width: 100%;

        .messageCount {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            @include mobile {
                display: none;
            }
        }

        .ant-select {
            width: 200px;
            position: relative;
            float: left;
            margin-left: 20px;

            @include mobile {
                margin-left: 10px;
                width: 100px;
            }
        }

        .ant-btn {
            position: relative;
            float: right;
            margin-right: 10px;

            &:nth-child(2) {
                margin-right: 50px;
                
                @include mobile {
                    margin-right: 10px;
                }
            }
        }
    }

    .ant-list {
        width: 90%;
        padding: 20px;
        margin-block: 20px;
        border-radius: 6px;
        box-shadow: 0 5px 25px 0 var(--box-shadow-color);
        -webkit-box-shadow: 0 5px 25px 0 var(--box-shadow-color);

        @include mobile {
            width: 96%;
            padding: 10px;
            margin-block: 10px;
        }
    }
}