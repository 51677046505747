@import "../../../styles/responsive.scss";

.paymentPageContainer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .paymentContent {
        display: flex;
        flex-direction: row;
        position: relative;
        height: auto;
        align-items: flex-start;

        @include mobile {
            flex-direction: column;
        }

        .leftSide, .rightSide {
            position: relative;
            width: 50%;
            padding: 10px;

            @include mobile {
                width: 100%;
            }
        }

        .leftSide {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            @include mobile {
                flex-direction: column;
                align-items: center;
            }

            .ant-typography {
                margin: 0;
            }

            .ant-avatar {
                width: 200px;
                height: 200px;
                border-radius: 10px;
                box-shadow: 0 0 5px var(--gray-4);

                @include mobile {
                    width: 250px;
                    height: 250px;
                }

                .ant-avatar-string {
                    font-size: 80px;
                }
            }

            .internshipInfo {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                padding-left: 30px;

                .internshipTitle {
                    color: var(--primary-color);
                }

                @include mobile {
                    padding-top: 15px;
                    padding-left: 0;
                }
            }
        }

        .rightSide {
            @include desktop {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }
}