@import "../../../styles/responsive.scss";

.applicationsContainer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .paginationContainer {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .ant-btn {
        border: none;
    }


    .listContainer {
        position: relative;
        height: auto;

        .matchedApplication {
            background-color: var(--box-shadow-color);
        }

        .ant-avatar {
            width: 50px;
            height: 50px;

            .ant-avatar-string {
                font-size: 15px;
            }
        }

        .tabContainer {
            position: relative;
            width: 100%;
            height: auto;
        }

        .updater {
            position: relative;
            margin-bottom: 10px;

            .editButton {
                position: absolute;
                top: 5px;
                right: 5px;
                border: none;
            }
        }

        .ant-typography {
            color: var(--primary-color);
        }

        .listHeader {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .ant-btn svg {
                margin-right: 5px;
            }
        }
    }
}