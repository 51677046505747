.internshipCardContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    height: auto;
    padding-block: 10px;
    border-radius: 6px;
    box-shadow: 0 5px 25px 0 var(--box-shadow-color);
    -webkit-box-shadow: 0 5px 25px 0 var(--box-shadow-color);
    cursor: pointer;

    .topSection {
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-inline: 12px;

        .label {
            border: 0.5px solid var(--gray-3);
            border-radius: 2px;
            padding: 2px 8px;
        }

        &>.heart {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .internshipCardContent {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        padding-inline: 12px;

        .internshipTitle {
            color: var(--primary-color);
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .locationSection {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-end;
            font-size: 12px;
            color: gray;
            white-space: nowrap;
        }

        .internshipTitle, .locationSection {
            margin-bottom: 5px;
        }

        .durationSection {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            .durationDefinition {
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }

    .avatarContainer {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .cardBottom {
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-inline: 12px;

        .ant-btn {
            width: 100%;
        }
    }

    

    .ant-btn, .ant-avatar, .topSection {
        margin-block: 10px;
        white-space: nowrap;
    }

    .ant-avatar {
        width: 100px;
        height: 100px;

        .ant-avatar-string {
            font-size: 50px;
        }
    }
}

.hidden {
    visibility: hidden;
}