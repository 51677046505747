@import "../../../styles/responsive.scss";

.internshipsContainer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .paginationContainer {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    } 


    .listContainer {
        position: relative;
        height: auto;

        .tabContainer {
            position: relative;
            width: 100%;
            height: auto;
        }

        .actionBox {
            width: 48%;
            float: left;
            margin-bottom: 20px;
            margin-inline: 1%;

            .internshipListItemContainer {
                width: 100%;
                margin-bottom: 0;
                margin-inline: 0;
            }

            @include notDesktop {
                width: 100%;
            }
        }

        .ant-typography {
            color: var(--primary-color);
        }

        .listHeader {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .addNewInternship {
                background-color: var(--green-5);
                color: var(--white);
            }

            @include mobile {
                flex-direction: column;
                margin-bottom: 10px;
            }

            h2.ant-typography {
                @include mobile {
                    font-size: 22px;
                }
            }
        }
    }
}