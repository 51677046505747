@import "../../../styles/responsive.scss";

.editApplicantProfileContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;

    .formHeader {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .uploadSection {
        position: relative;
        display: flex;
        flex-direction: row;

        .ant-btn {
            margin-right: 5px;
        }
    }

    @include notDesktop {
        padding-block: 10px;
        padding-inline: 15px;
    }

    .disabled {
        pointer-events: none;
    }

    .editProfileContent {
        position: relative;
        height: auto;

        .errorMessage {
            color: var(--red-7);
        }

        .ant-upload-span {
            width: 100px;
        }

        .richTextEditor {
            width: 100%;
            min-height: 100px;
            position: relative;
            outline: none;
            border: 1px solid var(--gray-4);
        }
    

        .ant-typography {
            color: #297FCA;
        }

        h1.ant-typography {
            margin-bottom: 0;

            @include mobile {
                font-size: 25px;
            }
        }

        .ant-input-group.ant-input-group-compact .ant-form-item {
            width: 49%;

            @include desktop {
                margin-bottom: 0;
            }
            
            &:first-child {
                margin-right: 2%;

                @include notDesktop {
                    margin-right: 0;
                }
            }

            @include notDesktop {
                width: 100%;
            }
        }

        .addNew {
            float: right;
            margin-right: 20px;

            @include notDesktop {
                margin-right: 0;
            }
        }

        .education {
            width: 30% !important;

            .ant-btn {
                border: none;
            }

            @include desktop {
                margin-bottom: 0;
            }

            &:last-child {
                width: 6% !important;

                @include mobile {
                    margin-left: 47%;
                }
            }
            
            &:not(:last-child) {
                margin-right: 1%;

                @include notDesktop {
                    margin-right: 0;
                }
            }

            @include notDesktop {
                width: 100% !important;
            }
        }
    }
}