@import "../../styles/responsive.scss";

.messageBox {
    .ant-input {
        margin-bottom: 10px;
    }

    .richTextEditor {
        height: 100px;
        width: 100%;
        position: relative;
        resize: none;
        outline: none;
        border: 1px solid var(--gray-4);
    }
}