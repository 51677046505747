@import "../../styles/responsive.scss";

.howInternseaWorksContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: calc(100vh - var(--header-height) - var(--footer-height));

    .howInternseaWorksContent {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &>.ant-typography:first-child {
            color: var(--primary-color);

            @include mobile {
                font-size: 30px;
            }
        }

        h2.ant-typography {
            color: var(--primary-color);

            @include mobile {
                font-size: 20px;
            }
        }

        span.ant-typography {
            font-size: 18px;

            @include mobile {
                text-align: justify;
            }
        }
    }
}