@import "../../../styles/responsive.scss";

.applicantApplicationsContainer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .listContainer {
        position: relative;
        height: auto;

        .matchedApplication {
            background-color: var(--box-shadow-color);
        }

        .ant-btn {
            margin-right: 2px;
            border: none;
        }

        .badgeContainer {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .badge {
            border-radius: 10px;
            color: white;
            font-weight: bold;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            box-shadow: 1px 1px 5px var(--black);
            cursor: default;
            padding-inline: 10px;
            padding-block: 5px;
        }

        .pendingApplication {
            background-color: orange;
        }

        .declinedApplication {
            background-color: red;
        }

        .approvedApplication {
            background-color: gray;
        }

        .activeApplication {
            background-color: blue;
        }

        .completedApplication {
            background-color: green;
        }

        .paid {
            background-color: var(--green-5);
        }

        .unpaid {
            background-color: var(--red-5);
        }

        .ant-avatar {
            left: 50%;
            transform: translateX(-50%);
            width: 50px;
            height: 50px;

            .ant-avatar-string {
                font-size: 15px;
            }
        }
    }
}