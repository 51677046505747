@import "../../styles/responsive.scss";

.notificationContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    z-index: 10000;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    min-height: 50px;
    background-color: var(--white);
    border-radius: 6px;
    box-shadow: 0 5px 25px 0 rgb(41 128 185 / 30%);
    -webkit-box-shadow: 0 5px 25px 0 rgb(41 128 185 / 30%);
    color: var(--primary-color);
    font-weight: bold;
    padding: 10px;
    animation-name: topToBottom;
    animation-duration: 0.5s;  
    animation-fill-mode: forwards;

    @include notDesktop {
        width: 90%;
    }

    .text {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 15px;
    }

    .success {
        color: var(--primary-color) !important;
    }

    .warning {
        color:	#ff9966 !important;
    }

    .error {
        color:	#cc3300 !important;
    }

    .succes_for_message_sending {
        color: green;
    }

    .confirmation {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-top: 10px;

        .ant-btn {
            background-color: #666699;
            color: var(--white);
            margin-left: 5px;
            border-radius: 5px;
            border: none;
            box-shadow: none;
        }
    }
}

.hideNotification {
    display: none !important;
}

@keyframes topToBottom {
    from {top: -30px;}
    to {top: 30px;}
}

.successBox {
    box-shadow: 0 5px 25px 0 rgb(41 128 185 / 30%) !important;
    -webkit-box-shadow: 0 5px 25px 0 rgb(41 128 185 / 30%) !important;
}

.warningBox {
    box-shadow: 0 5px 25px 0 rgb(255 153 102 / 30%) !important;
    -webkit-box-shadow: 0 5px 25px 0 rgb(255 153 102 / 30%) !important;
}

.errorBox {
    box-shadow: 0 5px 25px 0 rgb(204 51 0 / 30%) !important;
    -webkit-box-shadow: 0 5px 25px 0 rgb(204 51 0 / 30%) !important;
}

.succes_for_message_sendingBox {
    box-shadow: 0 5px 25px 0 rgb(0 200 0 / 30%) !important;
    -webkit-box-shadow: 0 5px 25px 0 rgb(0 200 0 / 30%) !important;
}