@import "../../styles/responsive.scss";

.ratingBox {

    .okButton {
        background-color: var(--green-5) !important;
        color: var(--white) !important;
    }

    .richTextEditor {
        width: 100%;
        height: 100px;
        resize: none;
        outline: none;
        border: 1px solid var(--gray-4);
        position: relative;
        margin-top: 10px;
    }
}