@import "../../../styles/responsive.scss";

.editCompanyProfileContainer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logoUploadSection {
        display: flex;
        flex-direction: row;

        .ant-btn {
            margin-right: 5px;
        }
    }

    .formHeader {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .disabled {
        pointer-events: none;
    }

    .errorMessage {
        color: var(--red-7);
    }

    .editProfileContent {
        position: relative;
        height: auto;

        .richTextEditor {
            position: relative;
            width: 100%;
            border: 1px solid var(--gray-4);
            outline: none;
            min-height: 200px;

            @include mobile {
                min-height: 100px;
            }
        }

        .ant-typography {
            color: var(--primary-color);
        }

        h1.ant-typography {
            margin-bottom: 0;

            @include mobile {
                font-size: 25px;
            }
        }

        .ant-input-group.ant-input-group-compact .ant-form-item {
            width: 49%;

            @include desktop {
                margin-bottom: 0;
            }
            
            &:first-child {
                margin-right: 2%;

                @include notDesktop {
                    margin-right: 0;
                }
            }

            @include notDesktop {
                width: 100%;
            }
        }

        .zipCode {
            height: 38px;
        }
    }
}