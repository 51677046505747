@import "../../../styles/responsive.scss";

.internshipsContainer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .listContainer {
        position: relative;
        height: auto;

        .internshipListItemContainer {
            width: 48%;
            float: left;
            margin-bottom: 20px;
            margin-inline: 1%;

            @include notDesktop {
                width: 100%;
            }
        }

        .favoriteHeader {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @include mobile {
                flex-direction: column;
                align-items: center;
            }

            .favoriteInternshipTitle {
                position: relative;

                @include mobile {
                    font-size: 22px;
                }
            }

            .ant-btn {
                position: relative;

                svg {
                    margin-right: 5px;
                }
            }
        }

        &>.ant-divider {
            margin-top: 5px;
        }

        .ant-typography {
            color: var(--primary-color);
        }
    }
}