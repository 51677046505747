@import "../../styles/responsive.scss";

.skeletonContainer {
    position: relative;
    width: 80%;
    height: auto;
    margin-left: 10%;
    margin-top: 50px;

    .ant-skeleton {
        margin-bottom: 30px;

        @include mobile {
            margin-bottom: 15px;
        }
    }

    @include mobile {
        width: 90%;
        margin-left: 5%;
        margin-top: 20px;
    }
}