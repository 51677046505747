.startCountRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--primary-color);
    font-weight: 600;


    .ant-progress {
        width: 100px;
        margin-inline: 10px;
    }

    svg {
        margin-left: 10px;
        color: #FADB14;
    }

    .sPlaceholder {
        visibility: hidden;
    }
}