@import "../../../styles/responsive.scss";

.applicantProfileContainer {
    position: relative;
    width: 100%;
    height: auto;
    padding-block: 50px;
    padding-inline: 300px;

    @include notDesktop {
        padding-block: 10px;
        padding-inline: 15px;
    }

    span.ant-typography {
        font-size: 15px;
    }

    .row {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            flex: 1;
            margin-bottom: 10px;
        }

        @include mobile {
            flex-direction: column;
        }
    }

    .ant-row {
        margin-top: 15px;
    }

    .profileContent {
        position: relative;
        padding: 50px;
        width: 100%;
        height: auto;
        border-radius: 20px;
        box-shadow: 0 5px 25px 0 var(--box-shadow-color);
        -webkit-box-shadow: 0 5px 25px 0 var(--box-shadow-color);

        .richTextEditorRejectReason {
            height: 100px;
            resize: none;
            outline: none;
            margin-left: 5px;
            border: 1px solid var(--gray-4);
        }

        .children {
            margin-top: 15px;

            .ant-btn-icon-only {
                border: none;
            }

            textarea {
                resize: none;
            }

            .ant-btn {
                margin: 2px;
            }

            .notesLine {
                position: relative;
                width: 100%;
                height: auto;

                .richTextEditor {
                    width: 100%;
                    outline: none;
                    position: relative;
                    min-height: 100px;
                    border: 1px solid var(--gray-4);
                }

                .ant-btn {
                    position: relative;
                    margin-top: 10px;
                }
            }
        }

        .ant-avatar {
            width: 100px;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            box-shadow: 0 0 5px var(--black);
            margin-bottom: 20px;

            .ant-avatar-string {
                font-size: 50px;
            }
        }

        .descriptionContainer {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            margin-block: 20px;

            .description {
                position: relative;
                width: 100%;
                color: var(--primary-color);
                white-space: pre-wrap;
            }
        }

        @include notDesktop {
            padding: 10px;
        }

        h1.ant-typography {
            @include mobile {
                font-size: 25px;
            }
        }

        h4.ant-typography {
            color: var(--primary-color);

            @include mobile {
                font-size: 15px !important;
            }
        }

        .ant-btn {
            @include mobile {
                transform: scale(0.8);
            }
        }

        h5.ant-typography {
            color: var(--primary-color);

            @include mobile {
                font-size: 12px !important;
            }
        }

        .actionSideContainer {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;

            @include mobile {
                flex-direction: column;
            }

            .actionSides {
                display: flex;
                flex-direction: column;
                flex: 1;
                padding-inline: 10px;

                @include mobile {
                    padding-inline: 0;
                }

                &:first-child {
                    @include desktop {
                        border-right: 1px solid var(--gray-4);
                    }
                }

                .approveRejectButtonsContainer {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;

                    &>.ant-btn {
                        margin-top: 1rem;
                    }
                }

                .saveReject {
                    width: 100px !important;
                }

                .gradeContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;

                    .actionButtons {
                        padding-left: 15px;
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        justify-content: flex-start;
                    }
                }
            }
        }


        .bottomSection {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            margin-block: 35px;

            @include mobile {
                margin-block: 15px;
            }
        }
    }
}