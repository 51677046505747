@import "../../styles/responsive.scss";

.notificationBellContainer {
    position: relative;

    .bell {
        transform: scale(0.8);
    }

    svg {
        cursor: pointer;
    }

    @include desktop {
        margin-left: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @include notDesktop {
        margin-top: 15px;
    }

}

.ant-tabs .tabContent {
    position: relative;
    width: 400px;
    height: 500px;
    overflow-y: auto;

    .notificationItem {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        margin-bottom: 5px;
        background-color: var(--box-shadow-color);
        color: var(--primary-color);
        border-radius: 5px;
        padding: 10px 0 20px 10px;

        .ant-btn {
            background-color: transparent;
            border: none;
        }

        .notificationDate {
            position: absolute;
            bottom: 5px;
            font-size: 10px;
            cursor: default;
        }


        .notificationItemBody {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            width: 90%;
            height: 100%;
            min-height: 20px;
            cursor: pointer;

            a {
                color: var(--primary-color);
            }
        }

        .notificationItemAction {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 10%;
            height: 100%;
            min-height: 20px;
        }
    }

    @include mobile {
        width: 250px;
        height: 250px;
    }
}