@import "../../styles/responsive.scss";
@import "../../styles/common.scss";

.breadCrumbContainer {
    position: relative;
    width: 100%;
    height: auto;

    .breadCrumb {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100px;

        @include CommonGradientBackground();

        @include mobile {
            display: none;
        }

        .ant-typography {
            color: var(--white);
        }

        h1.ant-typography {
            margin-bottom: 0;
        }

        .ant-breadcrumb-link {
            color: var(--white);
            font-size: 18px;
        }

        .ant-breadcrumb>ol>li {
            &:first-child {
                cursor: pointer;
            }

            &:nth-child(2) {
                cursor: default;
            }
        }

        .ant-breadcrumb-separator {
            color: var(--white);
            font-size: 18px;
        }
    }

    .content {
        position: relative;
        width: 100%;
        height: auto;
    }
}