@import "../../styles/responsive.scss";
@import "../../styles/common.scss";

.container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
}

.layout {
    position: relative;
    background-color: var(--white) !important;

    .menuItem {
        color: var(--primary-color);
    }

    .displayName {
        margin-right: 10px;
    }

    .menuItemWithLightColor {
        color: var(--white) !important;
    }

    .sidebar {
        position: fixed;
        top: var(--header-height);
        height: calc(100vh - var(--header-height));
        max-width: 350px;
        width: 100%;
        background-color: var(--white);
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        box-shadow: 1px 0 rgba(100, 100, 100, .2);
        overflow-y: auto;

        .ant-dropdown-trigger {
            margin-block: 10px;
        }

        .ant-btn {
            margin-block: 10px;
            height: 40px;
            border-radius: 5px;
            width: 100%;

            svg {
                margin-right: 5px;
            }
        }
    }

    .profilPhoto {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .header {
        position: sticky;
        top: 0; 
        z-index: 1; 
        width: 100%;
        height: var(--header-height) !important;
        transition: 0.5s all ease-in-out;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: var(--white);

        &>.ant-typography {
            position: absolute;
            left: 50px;
            top: 60px;
            color: var(--white);
            font-size: 14px;

            @include notDesktop {
                display: none;
            }
        }

        .mobileMenu {
            position: absolute !important;
            left: 30px;
            border: none !important;
            @include desktop {
                display: none;
            }
        }

        @include notDesktop {
            justify-content: center;
        }

        @include desktop {
            background-color: rgba($color: #FFFFFF, $alpha: 0.1);
        }

        .logo {
            position: relative;
            max-width: 220px;
            cursor: pointer;

            @include notDesktop {
                max-width: 150px;
            }
        }

        .ant-menu-horizontal {
            border-bottom: none !important;
        }

        .menu {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @include notDesktop {
                display: none;
            }

            .ant-btn {
                margin-inline: 10px;
                height: 40px;
                border-radius: 5px;
                font-weight: 700;

                svg {
                    margin-right: 5px;
                }
            }
        }
    }

    .showHeader {
        background-color: var(--white) !important;
    }

    .content {
        position: relative;
        width: 100%;
        height: auto;
        min-height: calc(100vh - var(--footer-height) - var(--header-height));
    }

    .contentUnderHeader {
        @include desktop {
            margin-top: calc(-1 * var(--header-height)) !important;
            min-height: calc(100vh - var(--footer-height)) !important;
        }
    }

    .ant-layout-footer {
        padding: 0 0;
    }

    .footer {
        position: relative;
        height: var(--footer-height) !important;
        background-color: var(--white) !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-inline: 170px;
        padding-top: 70px;
        border-top: 0.5px solid rgba(200, 200, 200, .2);
        color: #71849b;
        font-size: 17px;

        @include tablet {
            padding-inline: 70px;
        }

        @include mobile {
            padding-inline: 20px;
        }

        .footerTop {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            @include notDesktop {
                flex-direction: column;
            }

            .column {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                color: var(--primary-color);

                @include notDesktop {
                    margin-top: 10px;
                }

                .li {
                    cursor: pointer;
                    color: var(--black);
                }
            }

            .emailSection {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                color: var(--black);

                @include desktop {
                    margin-top: 20px;
                }

                svg {
                    margin-right: 10px;
                    color: var(--primary-color);
                }
            }

            .leftSide {
                @include desktop {
                    width: 54%;
                }

                .logo {
                    position: relative;
                    max-width: 220px;
                    margin-bottom: 30px;
                    cursor: pointer;
        
                    @include notDesktop {
                        max-width: 150px;
                    }
                }

                .description {
                    color: var(--black);
                    font-size: 15px;
                    margin-bottom: 30px;
                }

                .social {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    @include mobile {
                        justify-content: center;
                        width: 100%;
                        margin-bottom: 15px;
                    }

                    .ant-btn {
                        border: none !important;
                        margin-inline: 10px;
                    }
                }
            }

            .ant-typography {
                text-decoration: underline;
                color: var(--primary-color);

                @include mobile {
                    margin-bottom: 10px;
                }
            }

            .li {
                margin-bottom: 10px;
            }
        }

        .footerBottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-top: 0.5px solid rgba(100, 100, 100, .2);
            height: 80px;

            @include mobile {
                padding-block: 10px;
                font-size: 14px;
            }

            .copyright {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
            }

            .privacyAndTerms {
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

.backTop {
    position: fixed;
    height: 45px;
    width: 45px;
    right: 50px !important;
    bottom: 30px !important;
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;

    @include CommonGradientBackground();

    @include mobile {
        height: 30px;
        width: 30px;
        right: 40px !important;
    }

    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}