@import "../../styles/responsive.scss";

.review {
    position: relative;
    height: auto;
    margin-left: 5%;

    @include mobile {
        margin-left: 2%;
    }

    .reviewTopSection {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .ant-switch {
            margin-left: 10px;
        }
    }

    .ratings {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        position: relative;
        width: 100%;
        height: auto;

        @include mobile {
            flex-direction: column;
        }

        .startCounts {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 10px;
        }
    }

    .author {
        color: var(--black);

        .location {
            color: var(--gray-7);
        }
    }

    .comments {
        margin-top: 15px;

        .notes {
            color: var(--black);
        }

        .ant-btn {
            border: none;
        }

        .matchedMessage {
            background-color: var(--box-shadow-color);
        }

        .commentContent textarea {
            width: 100%;
            position: relative;
            height: 100px;
            resize: none;
            outline: none;
            border: 1px solid var(--gray-4);
            color: var(--black);
        }

        .commentStars {
            display: flex;
            flex-direction: row;

            .startDescriptions, .starValues {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-around;
            }

            .starText {
                margin-inline: 5px;
                color: #FADB14;
                font-weight: 900 !important;
                font-size: 16px;
            }

            .pipeline {
                margin-right: 5px;
                color: var(--gray-4);
                font-size: 16px;
            }

            .createdAt {
                color: var(--gray-7);
                font-weight: 700 !important;
                font-size: 10px;
            }
        }
    }

    .ant-typography {
        color: var(--primary-color);
    }

    .ant-comment-content-author-name>span {
        color: var(--primary-color) !important;
        font-weight: 600;
    }

    .ant-comment {
        color: var(--primary-color);
    }

    h2.ant-typography {
        @include mobile {
            font-size: 18px;
        }
    }

    @include mobile {
        width: 96%;
        padding: 10px;
        margin-block: 10px;
    }
}