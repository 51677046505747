@import "../../../styles/responsive.scss";

.applicationTermsContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: calc(100vh - var(--header-height) - var(--footer-height));

    &>.ant-typography {
        margin-top: 50px;
        @include mobile {
            font-size: 20px;
        }
    }

    .ant-typography {
        color: var(--black);
    }

    .ant-checkbox-wrapper {
        margin-top: 20px;
        @include mobile {
            margin-inline: 10px;
        }
    }

    .ant-checkbox-wrapper>span {
        color: var(--primary-color);
    }

    .ant-btn {
        margin-block: 50px;

        @include mobile {
            margin-block: 20px;
        }
    }

    .applicationTermsContent {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        span.ant-typography {
            font-size: 18px;
        }
    }
}