@import "../../../styles/responsive.scss";

.createUpdateInternshipContainer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .formContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        height: auto;

        .formTitle {

            h2.ant-typography {
                @include mobile {
                    font-size: 22px;
                }
            }
        }

        .freeOfChargeText {
            font-size: 12px;
            color: gray;
        }

        .placeholder {
            visibility: hidden;
        }

        .ant-input-group.ant-input-group-compact .ant-form-item {
            width: 49%;

            @include desktop {
                margin-bottom: 0;
            }
            
            &:first-child {
                margin-right: 2%;

                @include notDesktop {
                    margin-right: 0;
                }
            }

            @include notDesktop {
                width: 100%;
            }
        }

        .richTextEditor {
            position: relative;
            width: 100%;
            border: 1px solid var(--gray-4);
            outline: none;
            min-height: 200px;

            @include mobile {
                min-height: 100px;
            }
        }

        .addNew {
            float: right;
        }

        .ant-typography {
            color: var(--primary-color);
        }

        .errorMessage {
            color: var(--red-7);
        }
    }
}