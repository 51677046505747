@mixin mobile {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin notMobile {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 1200px) and (min-width: 769px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin notDesktop {
    @media (max-width: 1200px) {
        @content;
    }
}
